import CreateUserProfile from '@/components/modal/modal-content/CreateUserProfile'
import { FC } from 'react'
import { useGeneralContext } from '../context/GeneralContext'

const DrawerContent: FC = () => {
  const { drawerType } = useGeneralContext()

  const modalContentGenerator = () => {
    switch (drawerType) {
      case 'create-profile':
        return <CreateUserProfile />
      default:
        return <p>No drawer content ...</p>
    }
  }
  return <div className={'modal-content'}>{modalContentGenerator()}</div>
}

export default DrawerContent
