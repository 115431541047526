import { globalFetchPolicy } from '@/components/layout/DashboardLayout'
import { useLoginMutation, useMeLazyQuery, useRegisterMutation } from '@/graphql'
import { getAuthToken, removeAuthToken, setAuthToken } from '@/helpers/auth'
import { RoleTypes } from '@/types'
import { FetchResult } from '@apollo/client'
import { message } from 'antd'
import { createContext, FC, PropsWithChildren, useContext, useEffect, useState } from 'react'

export type LoginFields = 'email' | 'password';
export type RegistrationFields = 'username' | 'email' | 'password';

type AuthProviderRegistration = (fields: Record<RegistrationFields, string>) => Promise<void>;

type AuthContextProps = {
  role: RoleTypes,
  user: MeQuery['me']
  organization: Maybe<string>
  login: (input: UsersPermissionsLoginInput) => Promise<FetchResult<LoginMutation>>;
  register: AuthProviderRegistration;
  logout: () => Promise<void>;
  getUser: () => Promise<MeQuery['me']>;
  setUser: (user: MeQuery['me'] | null) => void
  setRole: (role: RoleTypes | null) => void
};

type AuthProviderProps = PropsWithChildren<Partial<AuthContextProps>>;

const AuthContext = createContext<AuthContextProps | undefined>(undefined)

export function useAuth():AuthContextProps {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return context
}

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState<MeQuery['me'] | null>(null)
  const [role, setRole] = useState<RoleTypes>(null)

  const [getMe, { data: me, error, loading }] = useMeLazyQuery({ fetchPolicy: globalFetchPolicy })

  const [loginMutation] = useLoginMutation()
  const [registerMutation] = useRegisterMutation()

  useEffect(() => {
    if (!loading) {
      if (error) {
        setUser(null)
        setRole(undefined)
      } else {
        setUser(me?.me)
        setRole(me?.me?.role?.data?.attributes?.name.toLowerCase() as RoleTypes)
      }
    }
  }, [error, loading, me])
  // const user = error ? null : me?.me
  const userRole = error ? undefined : me?.me?.role?.data?.attributes?.name.toLowerCase() as RoleTypes
  const organization = me?.me?.organization?.data?.attributes?.slug

  useEffect(() => {
    getMe({
      fetchPolicy: 'no-cache',
    }).then()
  }, [])

  const login = async (input: UsersPermissionsLoginInput) => {
    return await loginMutation({
      fetchPolicy: globalFetchPolicy,
      variables: {
        input,
      },
      onCompleted: (data) => {
        if (data?.login.jwt) {
          setAuthToken(data.login.jwt)
        }
        getMe({ context: { headers: { authorization: `Bearer ${getAuthToken()}` } } })
      },
      onError: (error) => {
        message.error(error.message)
      },
    })
  }

  const register = async ({ email, username, password }: Record<RegistrationFields, string>) => {
    await registerMutation({
      fetchPolicy: globalFetchPolicy,
      variables: {
        input: { email, password, username },
      },
    }).then((result) => {
      if (result?.data?.register?.user?.email) {
        localStorage.setItem('email', result.data.register.user.email)
        getMe({ context: { headers: { authorization: `Bearer ${getAuthToken()}` } } })
      }
    })
  }

  const logout = async () => {
    removeAuthToken()
    localStorage.clear()
    await getMe({ context: { headers: { authorization: undefined } } })
    setUser(null)
  }
  const getUser = async () => {
    const { data } = await getMe()
    return data?.me
  }
  return (
    <AuthContext.Provider
      value={{
        role,
        user,
        organization,
        setUser,
        setRole,
        login,
        logout,
        register,
        getUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

