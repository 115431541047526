import { globalFetchPolicy } from '@/components/layout/DashboardLayout'
import UserAvatar from '@/components/user-avatar/UserAvatar'
import { useCreateUserProfileMutation } from '@/graphql'
import { getUserProfileRoleOptions } from '@/helpers/getUserProfileRoleOptions'
import { removeUnderscores } from '@/helpers/removeUnderscores'
import useCompanyOptions from '@/hooks/useCompaniesOptions'
import { levelOfApprovalOptions } from '@/pages/company/content/employees/LevelOfApprovalSelect'
import { Form, Row, Space } from 'antd'
import { useForm } from 'antd/es/form/Form'
import React, { BaseSyntheticEvent, Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import BlackNotification from '../../blackNotification/BlackNotification'
import Button from '../../button/Button'
import { useGeneralContext } from '../../context/GeneralContext'
import FormItem from '../../form-item/FormItem'
import Typography from '../../typography/Typography'


const responsibilities: EnumUserprofileResponsibility[] = ['administrator', 'site_representative', 'external_service_provider', 'contractor', 'engineer']
const responsibilityOptions = responsibilities?.map(it => ({
  label: removeUnderscores(it),
  value: it,
}))
const { Title } = Typography
type Props = {
  onCancel?: () => void
  setIsLocalModalOpen?: Dispatch<SetStateAction<boolean>>;
}
const CreateUserProfile: FC<Props> = ({onCancel, setIsLocalModalOpen}) => {
  const [form] = useForm()
  const { companyOptions, loading: companiesDataLoading } = useCompanyOptions('all')
  const {pathname} = useLocation()

  // useEffect(() => {
  //   form.resetFields()
  // },  [form, initialValues]);

  console.log(pathname)
  const { currentId, setIsModalOpen, setIsDrawerOpen } = useGeneralContext()
  const [createUserProfile, {loading}] = useCreateUserProfileMutation()

  const [fullName, setFullName] = useState('')
  const [isLevelOfApprovalVisible, setIsLevelOfApprovalVisible] = useState(false)
  const onFinish = async (values: UserProfileInput) => {
    await createUserProfile({
      fetchPolicy: globalFetchPolicy,
      variables: {
        input: {
          fullName: values?.fullName,
          company: values?.company,
          primaryEmail: values?.primaryEmail,
          secondaryEmail: values?.secondaryEmail,
          primaryPhone: values?.primaryPhone,
          secondaryPhone: values?.secondaryPhone,
          address: values?.address,
          responsibility: values?.responsibility as InputMaybe<EnumUserprofileResponsibility>,
          role: values?.role,
        },
      },
      onError: (error) => {
        console.log(error)
        BlackNotification(error.message)
        setIsLocalModalOpen ? setIsLocalModalOpen(false) : setIsModalOpen(false)
        setIsDrawerOpen(false)
      },
      onCompleted: () => {
        form.resetFields()
        BlackNotification('User profile created successfully')
        setIsLocalModalOpen ? setIsLocalModalOpen(false) : setIsModalOpen(false)
        setIsDrawerOpen(false)
      },
      refetchQueries: 'active'
    })
  }

  const onFullNameChange = (e: BaseSyntheticEvent) => {
    setFullName(e.target.value)
  }
  const hasSpace = /^[^\s].*\s+.*[^\s]$/.test(fullName)
  const initialValues = {
    company: currentId ? currentId : undefined,
    responsibility: pathname === '/dashboard/application/approval/' ? 'site_representative' : null,
    levelOfApproval: 'firstAndOnly'
  }
  const onResponsibilitySelect = (option: EnumUserprofileResponsibility | string) => {
    console.log(option)
    if(option === 'administrator') {
      setIsLevelOfApprovalVisible(true)
    }
  }
  const roleOptions = getUserProfileRoleOptions()


  return (
    <>
      <Row align={'middle'} style={{ marginBottom: 20 }}>
        <Space size={'middle'} align={'center'}>
          <UserAvatar userFullName={hasSpace ? fullName : 'User Name'} />
          <Title level={3} style={{ marginBottom: 0 }}>{'Create user profile'}</Title>
        </Space>
      </Row>
      <Form form={form} layout={'vertical'} className={'form-content'} onFinish={onFinish} initialValues={initialValues}>
        <FormItem
          onInput={onFullNameChange}
          label={'Full name'}
          name={'fullName'}
          type={'input'}
          placeholder={'Enter full name'}
        />
        <FormItem
          name={'company'}
          label={'Company'}
          type={'select'}
          selectOptions={companyOptions}
          placeholder={'Select Company'}
          loading={companiesDataLoading}
          showSearch={true}
        />
        <FormItem
          label={'User role'}
          name={'role'}
          type={'select'}
          selectOptions={roleOptions}
          placeholder={'Select user profile role'}
        />
        <FormItem
          label={'Responsibilities type'}
          name={'responsibility'}
          type={'select'}
          selectOptions={responsibilityOptions}
          handleSelectChange={onResponsibilitySelect}
          placeholder={'Select responsibility type'}
        />
        {isLevelOfApprovalVisible && (
          <FormItem
            name={'levelOfApproval'}
            label={'Level of Approval'}
            type={'select'}
            selectOptions={levelOfApprovalOptions}
            placeholder={'Select level of approval'}
          />
        )}
        <FormItem
          label={'Primary email'}
          name={'primaryEmail'}
          type={'input'}
          placeholder={'Enter primary email'}
        />
        <FormItem
          label={'Secondary email'}
          name={'secondaryEmail'}
          type={'input'}
          placeholder={'Enter secondary email (optional)'}
          required={false}
        />
        <FormItem
          label={'Primary phone number'}
          name={'primaryPhone'}
          type={'input'}
          placeholder={'Enter primary phone number (optional)'}
          required={false}
        />
        <FormItem
          label={'Secondary phone number'}
          name={'secondaryPhone'}
          type={'input'}
          placeholder={'Enter secondary phone number (optional)'}
          required={false}
        />
        <FormItem
          label={'Address'}
          name={'address'}
          type={'input'}
          placeholder={'Enter address (optional)'}
          required={false}
        />

        <Row justify={'end'}>
          <Button text={'Cancel'} btnType={'ghost'} onClick={onCancel} style={{ marginRight: 12 }} />
          <Button text={'Create'} btnType={'primary'} htmlType={'submit'} loading={loading} />
        </Row>
      </Form>
    </>
  )
}
export default CreateUserProfile
