import { longDash } from '@/helpers/globalVariables'
import { Col, Input, Row } from 'antd'
import { FC } from 'react'
import Typography from '../typography/Typography'
import './InfoTextItem.less'

type Props = {
  label: string,
  text: Maybe<string>
}
const { Text } = Typography
const InfoTextItem: FC<Props> = ({ label, text }) => {

  return (
    <Row style={{borderBottom: '1px solid #f3f3f3'}}>
      <Col span={8}>
        <Text color={'light-gray'}>{`${label}:`}</Text>
      </Col>
      <Col span={16}>
        <Text weight={'w600'}>{text || longDash}</Text>
        {/*<Input*/}
        {/*  className={'info-item-input'}*/}
        {/*  bordered={false} value={text || longDash}*/}
        {/*/>*/}
      </Col>
    </Row>
  )
}

export default InfoTextItem
