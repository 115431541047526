type RouteObject = {
  [key: string]: string | RouteObject;
};

type RouteEntity = string | RouteObject;
export const ROUTES = {
  website: {
    index: '',
    home: 'home',
    quote: 'free-quote',
    privacyPolicy: 'privacy-policy',
    termsAndConditions: 'terms-and-conditions',
    auth: 'auth',
    notFound: 'not-found',
    emailConfirmed: 'email-confirmed',
    emailNotConfirmed: 'email-not-confirmed',
    emailConfirmation: 'auth/email-confirmation',
    forgotPasswordConfirmation: 'auth/forgot-password-confirmation',
  },
  dashboard: {
    index: 'dashboard',
    overview: 'overview',
    projects: 'projects',
    applications: 'applications',
    application: 'application',
    companies: 'companies',
    company: 'company',
    customers: 'customers',
    users: 'users',
    agentProfessionalsTasks: 'agents-professionals/tasks',
    tasks: 'tasks',
    task: 'task',
    projectOverview: 'project-overview',
    projectApplications: 'project-applications',
    projectTasks: 'project-tasks',
    reports: 'reports',
    splitApplications: 'split-application',
    reviewApplicationsCards: 'review-applications-cards',
    serviceProviderApplications: 'service-provider/applications',
    governmentWorks: 'government-works',
    profile: 'profile',
    templateGroups: 'template-groups',
    templateSubject: 'template-subject',
    templateSubjects: 'template-subjects',
    templateDocument: 'template-document',
    managerMap: 'manager-map',
    splittingApplicationDetails: 'splitting-applications-details',
    createProject: 'create-project',
    createTask: 'create-task',
    createNote: 'create-note',
    createApplication: 'create-application',
    platformSettings: 'platform-settings',
  },
}

const mapRoutesToParent = (
  route: RouteEntity,
  parentPath: string,
): RouteEntity => {
  return Object.entries(route).reduce((acc, [key, value]) => {
    const routePath = `${parentPath}${
      key === 'index' || parentPath === '/' ? '' : '/'
    }`

    if (typeof value === 'string') {
      return {
        ...acc,
        [key]: `${routePath}${key === 'index' ? '' : value}`,
      }
    }

    return {
      ...acc,
      [key]: mapRoutesToParent(value, `${routePath}${value.index}`),
    }
  }, {})
}

export const websiteRoutes = mapRoutesToParent(
  ROUTES,
  '/',
) as typeof ROUTES
