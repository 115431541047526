import BlackNotification from '@/components/blackNotification/BlackNotification'
import { useAuth } from '@/components/context/AuthProvider'
import FormItem from '@/components/form-item/FormItem'
import FormItemSubtitleDivider from '@/components/form-item/FormItemDivider'
import InfoTextItem from '@/components/info-text-item/InfoTextItem'
import { globalFetchPolicy } from '@/components/layout/DashboardLayout'
import { useCompaniesQuery, useRolesQuery, useUpdateUserMutation, useUpdateUserProfileMutation, useUserProfileQuery, useUserQuery } from '@/graphql'
import { getUserProfileRoleOptions } from '@/helpers/getUserProfileRoleOptions'
import { longDash } from '@/helpers/globalVariables'
import useCompanyOptions from '@/hooks/useCompaniesOptions'
import { Col, Form, message, Radio, RadioChangeEvent, Row, Spin, Switch, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import Button from '../../button/Button'
import { useGeneralContext } from '../../context/GeneralContext'
import Typography from '../../typography/Typography'
import avatarPlaceholder from './images/avatar_user_placeholder.jpg'
import FormItemDivider from '@/components/form-item/FormItemDivider'

const { Title, Text } = Typography
const UserProfileReview = () => {
  const { role } = useAuth()
  const { setIsModalOpen, setTriggerRefetch, currentId } = useGeneralContext()
  const [updateUserProfile, { loading: isProfileUpdating }] = useUpdateUserProfileMutation()
  const [statusValue, setStatusValue] = useState('pending')
  const [isEditable, setIsEditable] = useState(false)
  const [selectedRole, setSelectedRole] = useState<EnumUserprofileRole | null>(null)

  const { companyOptions, loading: companiesDataLoading } = useCompanyOptions('all')

  useEffect(() => {
    if (role === 'manager') {
      setIsEditable(true)
    }
  }, [role])
  // const { data: companiesData, loading: companiesDataLoading } = useCompaniesQuery({
  //   fetchPolicy: globalFetchPolicy,
  // })
  // const companies = companiesData?.companies?.data
  const { data: rolesData, loading: rolesDataLoading } = useRolesQuery({
    fetchPolicy: globalFetchPolicy,
  })
  const allRoles = rolesData?.usersPermissionsRoles?.data as UsersPermissionsRoleEntity[]
  const roles = allRoles?.filter(
    it =>
      it?.attributes?.name !== 'Authenticated' &&
      it?.attributes?.name !== 'Public' &&
      it?.attributes?.name !== 'Manager',
  )
  const { data, loading } = useUserProfileQuery({
    fetchPolicy: globalFetchPolicy,
    variables: { id: currentId },
  })
  const userData = data?.userProfile?.data as UserProfileEntity

  useEffect(() => {
    if (!loading) {
      setStatusValue(userData?.attributes?.status as string)
      setSelectedRole(userData?.attributes?.role as EnumUserprofileRole)
    }
  }, [loading, userData?.attributes?.role, userData?.attributes?.status])

  const onFinish = (values: UserProfileInput) => {
    console.log(values)
    userData?.id && updateUserProfile({
      fetchPolicy: globalFetchPolicy,
      variables: {
        id: userData?.id,
        data: {
          company: values?.company,
          status: statusValue as InputMaybe<EnumUserprofileStatus>,
          role: values?.role,
          isDefaultAgent: values?.isDefaultAgent,
        },
      },
      onCompleted: () => {
        BlackNotification('User status updated')
        setIsModalOpen(false)
      },
      onError: (e) => {
        BlackNotification(e.message)
      },
      refetchQueries: 'active',
    })
  }

  const onCancel = () => {
    setIsModalOpen(false)
  }
  const onChange = (e: RadioChangeEvent) => {
    setStatusValue(e.target.value)
  }

  console.log(userData)
  const initialValues = {
    role: userData?.attributes?.role,
    company: userData?.attributes?.company?.data?.id,
    isDefaultAgent: userData?.attributes?.isDefaultAgent,
  }
  const roleOptions = getUserProfileRoleOptions()
const onRoleChange = (value: string) => {
  console.log(value)
  setSelectedRole(value as EnumUserprofileRole)
}
  return loading || rolesDataLoading || companiesDataLoading ? (
    <Spin />
  ) : (
    <>
      <FormItemDivider title={'User profile review'} marginTop={0} subTitle={userData?.attributes?.fullName ?? longDash} />

      <Row justify={'space-between'} style={{marginBottom: 20}}>
        <Col span={18}>
          <div className={'additional-info'}>
            <InfoTextItem label={'Role'} text={userData?.attributes?.role} />
            <InfoTextItem label={'Email'} text={userData?.attributes?.primaryEmail} />
            {userData?.attributes?.secondaryEmail && <InfoTextItem label={'Secondary email'} text={userData?.attributes?.secondaryEmail} />}
            <InfoTextItem label={'Phone'} text={userData?.attributes?.primaryPhone} />
            {userData?.attributes?.secondaryPhone && <InfoTextItem label={'Secondary phone'} text={userData?.attributes?.secondaryPhone} />}
            {userData?.attributes?.address && <InfoTextItem label={'Address'} text={userData?.attributes?.address} />}
          </div>
        </Col>
        <Col span={5}>
          <div className={'avatar-section'}>
            <img
              src={userData?.attributes?.avatar?.data?.attributes?.url ?? avatarPlaceholder}
              alt={'avatar'}
            />
          </div>
        </Col>
      </Row>
      <Form layout={'vertical'} className={'form-content'} onFinish={onFinish} initialValues={initialValues}>
        <FormItem
          label={'User Company'}
          name={'company'}
          type={'select'}
          selectOptions={companyOptions}
          loading={companiesDataLoading}
          isDisabled={!isEditable}
        />

        <FormItem
          isDisabled={!isEditable}
          label={'User role'}
          name={'role'}
          type={'select'}
          selectOptions={roleOptions}
          handleSelectChange={onRoleChange}
          placeholder={'Select user profile role'}
        />
        {selectedRole === 'agent' && (
          <Tooltip
            title={'\'Default Agent\' means all new applications will be automatically allocated to that user.'}
            placement={'bottomLeft'}
          >
            <div>
              <FormItem
                name={'isDefaultAgent'}
                type={'switch'}
                label={'Is default agent'}
                isDisabled={!isEditable}
                defaultValue={userData?.attributes?.isDefaultAgent}
              />
            </div>
          </Tooltip>

        )}

        <FormItemSubtitleDivider label={'User account status:'} marginTop={0} />

        <Radio.Group
          onChange={onChange}
          value={statusValue}
          style={{ display: 'flex', flexDirection: 'column', marginBottom: 20 }}
          disabled={!isEditable}
        >
          <Radio value={'pending'} style={{ marginBottom: 10 }} className={'user-radio-item'}>
            <div className={'user-radio-item-content'}>
              <Text size={'md'} className={'radio-item-name'}>
                {'Pending'}
              </Text>
              <Tooltip
                overlayClassName={'main-tooltip'}
                placement={'bottomRight'}
                title={
                  'Default status that indicates that user\'s registration information is needed to be validated by Manager '
                }
              >
                <div className={'tool-tip-info'}>{'i'}</div>
              </Tooltip>
            </div>
          </Radio>
          <Radio value={'active'} style={{ marginBottom: 10 }} className={'user-radio-item'}>
            <div className={'user-radio-item-content'}>
              <Text size={'md'} className={'radio-item-name'}>
                {'Active'}
              </Text>
              <Tooltip
                overlayClassName={'main-tooltip'}
                placement={'bottomRight'}
                title={'User allowed to access all the application functionality according their user Role'}
              >
                <div className={'tool-tip-info'}>{'i'}</div>
              </Tooltip>
            </div>
          </Radio>
          <Radio value={'inactive'} style={{ marginBottom: 10 }} className={'user-radio-item'}>
            <div className={'user-radio-item-content'}>
              <Text size={'md'} className={'radio-item-name'}>
                {'Inactive'}
              </Text>
              <Tooltip
                overlayClassName={'main-tooltip'}
                placement={'bottomRight'}
                title={'Inactive users can see their dashboards but not allowed to create any content'}
              >
                <div className={'tool-tip-info'}>{'i'}</div>
              </Tooltip>
            </div>
          </Radio>
          <Radio value={'blocked'} style={{ marginBottom: 10 }} className={'user-radio-item'}>
            <div className={'user-radio-item-content'}>
              <Text size={'md'} className={'radio-item-name'}>
                {'Blocked'}
              </Text>
              <Tooltip
                overlayClassName={'main-tooltip'}
                placement={'bottomRight'}
                title={'Blocked users are not allowed to log in into platform'}
              >
                <div className={'tool-tip-info'}>{'i'}</div>
              </Tooltip>
            </div>
          </Radio>
        </Radio.Group>


        <div className={'btn-block'} style={{ justifyContent: 'flex-end' }}>
          <Button text={'Cancel'} btnType={'ghost'} onClick={onCancel} style={{ marginRight: 12 }} />
          <Button text={'Save changes'} btnType={'primary'} htmlType={'submit'} loading={isProfileUpdating} />
        </div>
      </Form>
    </>
  )
}
export default UserProfileReview
