import { useCompanyNamesQuery } from '@/graphql';
import { useMemo } from 'react';
import { globalFetchPolicy } from '@/components/layout/DashboardLayout'
import { SelectOptionsType } from '@/components/form-item/FormItem'

interface UseCompanyOptionsHook {
  companyOptions: SelectOptionsType[];
  loading: boolean;
}

const useCompanyOptions = (mode: 'municipalities' | 'all'| 'approvals' = 'all'): UseCompanyOptionsHook => {
  let filters
  switch (mode) {
    case 'all': filters = {}
      break
    case 'approvals': filters = {companyType: {in: ['service_provider', 'government', 'professional']}}
      break
    case 'municipalities': filters = {applicationType: {slug: {eq: 'municipality'}}}
      break
    default: filters = {}
  }
  const { data: companiesDataResponse, loading } = useCompanyNamesQuery({
    fetchPolicy: globalFetchPolicy,
    variables: {
      filter: filters,
      pagination: { limit: -1 }
    },
  });

  const companyOptions = useMemo(() => {
    const companiesData = companiesDataResponse?.companies?.data as CompanyEntity[];
    return companiesData?.map((it) => ({
      label: it?.attributes?.name as string,
      value: it?.id as string,
    })) || [{ label: '', value: '' }];
  }, [companiesDataResponse]);

  return {
    companyOptions,
    loading,
  };
};

export default useCompanyOptions;
