import { SelectOptionsType } from '@/components/form-item/FormItem'
import { capitalize } from 'lodash'

const userProfileOptions: EnumUserprofileRole[] = ['client', 'agent', 'contractor', 'government', 'service_provider', 'engineer']
export const getUserProfileRoleOptions = (): SelectOptionsType[] => {
  return userProfileOptions?.map(it => ({
    label: capitalize(it),
    value: it,
  })) as SelectOptionsType[]
}

